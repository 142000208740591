if ($('.js-slideshow').length > 0) {

	$('html')
		.imagesLoaded(
			
			function() {
				
				$('.js-slideshow')
					.each(
			
						function(index){
			
							//
							// Variables
							//
			
								var slideshowObject = $(this),
									slideshowSpeed = 550,
									slideshowActiveCount = 0;
			
							//
							// Options
							//
			
								var slideshowOptions,
									slideshowOptTabs = slideshowObject.data('slideshow-tabs'),
									slideshowOptAuto = slideshowObject.data('slideshow-auto'),
									slideshowOptType = slideshowObject.data('slideshow-type'),
									marginWidth = ($(window).width() / 8),
									stagePad = $(window).width() / 8,
									marginWidthSml = ($(window).width() / 8) / 2.5,
									stagePadSml = ($(window).width() / 8) / 2.5;
									
								var slideSpeed = 1;	
			
							//
							// Add Required Owl Class
							//
			
								slideshowObject.addClass('owl-carousel');
			
							//
							// Options
							//
			
								if (slideshowOptType === 'hero-slideshow') {
			
									slideshowOptions = {
										autoHeight: true,
									    items: 1,
										nav: false,
										loop: true,
										dots: true,
										autoplay: true,
									}
			
								}
								else if (slideshowOptType === 'icon-carousel') {
									
									slideshowOptions = {
										autoHeight: true,
									    items: 6,
										nav: true,
										navText: [,],
										dots: false,
										autoplay: false,
										loop: false,
										responsive: {
											0 : {
												items: 2,
												autoplay: true,
												loop: true,
											},
											600 : {
												items: 3,
												autoplay: true,
												loop: true,
											},
											700 : {
												items: 4,
												autoplay: true,
												loop: true,
											},
											900 : {
												items: 4,
												autoplay: true,
												loop: true,
											},
											1200 : {
												items: 6,
												autoplay: false,
												loop: false,
											},
										}
									}
									
								}
								else if (slideshowOptType === 'product-carousel') {
									
									slideshowOptions = {
										autoHeight: true,
									    items: 5,
									    margin: 20,
										nav: true,
										navText: [,],
										dots: false,
										autoplay: true,
										loop: false,
										responsive: {
											0 : {
												items: 1,
												loop: true,
											},
											600 : {
												items: 2,
												loop: true,
											},
											900 : {
												items: 3,
												loop: true,
											},
											1200 : {
												items: 4,
												loop: false,
											},
										}
									}
									
								}
								else if (slideshowOptType === 'image-carousel') {
									
									slideshowOptions = {
										autoHeight: true,
									    items: 4,
									    margin: 20,
										nav: true,
										navText: [,],
										dots: false,
										autoplay: true,
										loop: true,
										responsive: {
											0 : {
												items: 1,
												loop: true,
											},
											900 : {
												items: 2,
												loop: true,
											},
											900 : {
												items: 3,
												loop: true,
											},
											1200 : {
												items: 4,
												loop: false,
											},
										}
									}
									
								}
								else if (slideshowOptType === 'stat-carousel') {
									
									slideshowOptions = {
										autoHeight: true,
									    items: 3,
									    margin: 0,
										nav: true,
										navText: [,],
										dots: false,
										autoplay: true,
										loop: false,
										responsive: {
											0 : {
												items: 1,
											},
											600 : {
												items: 2,
											},
											700 : {
												items: 2,
											},
											900 : {
												items: 3,
											}
										}
									}
									
								}
								else if (slideshowOptType === 'twitter-slideshow') {
									
									slideshowOptions = {
										autoHeight: true,
									    items: 1,
										nav: true,
										navText: [,],
										dots: false,
										autoplay: true,
										loop: true,
									}
									
								}
								else if (slideshowOptType === 'timeline-slideshow') {
									
									slideshowOptions = {
										autoHeight: true,
									    items: 1,
										nav: false,
										navText: [,],
										dots: true,
										autoplay: true,
										loop: true,
									}
									
								}
								else if (slideshowOptType === 'product-slideshow-single') {
									
									slideshowOptions = {
										autoplayTimeout: 2000,
										autoHeight: true,
									    items: 1,
										nav: false,
										dots: false,
										autoplay: true,
										loop: true,
									}
									
								}
								else {
			
									// Default
			
										slideshowOptions = {
											smartSpeed: slideshowSpeed,
											nav: false,
											dots: false,
											loop: false,
											items: 1,
										}
			
								}
			
							//
							// Init Callback
							//
								 
								slideshowObject
									.on(
										'initialized.owl.carousel',
										function(event) {
											
											slideshowActiveCount = event.item.index;
												
											if (slideshowActiveCount == '0') {
			
												$('li[data-slideshow-prev=' + slideshowOptType + ']').addClass('first');
												$('li[data-slideshow-next=' + slideshowOptType + ']').removeClass('last');
			
											} else if (slideshowActiveCount == $(this).find('.owl-item').length - 1) {
												
												$('li[data-slideshow-next=' + slideshowOptType + ']').addClass('last');
												$('li[data-slideshow-prev=' + slideshowOptType + ']').removeClass('first');
												
											} else {
												
												$('li[data-slideshow-prev=' + slideshowOptType + ']').removeClass('first');
												$('li[data-slideshow-next=' + slideshowOptType + ']').removeClass('last');
												
											}
			
										}
									);
			
							//
							// Create
							//
			
								var slideshowObj = slideshowObject.owlCarousel(slideshowOptions),
									slideshowPaginationObj = $('[data-slideshow-pagination=' + slideshowOptType + ']'),
									slideshowNext = $('[data-slideshow-next=' + slideshowOptType + ']'),
									slideshowPrev = $('[data-slideshow-prev=' + slideshowOptType + ']');
			
							//
							// Functions
							//
			
								// Change Slide
			
									function slideshowChangeSlide(index) {
			
										slideshowObj.trigger('to.owl.carousel', [index, slideshowSpeed, true]);
										slideshowChangePagination(index);
			
									}
			
								// Change Pagination
			
									function slideshowChangePagination(index) {
			
										slideshowPaginationObj
											.children('li[data-count=' + index + ']')
											.addClass('active');
			
									}
			
							//
							// Pagination
							//
			
								// Set First Pagination Element Active
			
									slideshowPaginationObj
										.find('li:first-child')
										.addClass('active');
			
								// Click
			
									slideshowPaginationObj
										.find('li')
										.click(
											function(event){
			
												slideshowActiveCount = $(this).data('count');
												slideshowChangeSlide(slideshowActiveCount);
												event.preventDefault();
			
											}
										);
			
								// Change - Start
			
									slideshowObj
										.on(
											'change.owl.carousel',
											function(event) {
			
												slideshowPaginationObj
													.children('li')
													.removeClass('active');
			
											}
										);
			
								// Change - Finished
			
									slideshowObj
										.on(
											'translate.owl.carousel',
											function(event) {
			
												slideshowActiveCount = event.item.index;
												slideshowChangePagination(slideshowActiveCount);
												
												if (slideshowActiveCount == '0') {
			
													$('li[data-slideshow-prev=' + slideshowOptType + ']').addClass('first');
													$('li[data-slideshow-next=' + slideshowOptType + ']').removeClass('last');
			
												} else if (slideshowActiveCount == $(this).find('.owl-item').length - 1) {
													
													$('li[data-slideshow-next=' + slideshowOptType + ']').addClass('last');
													$('li[data-slideshow-prev=' + slideshowOptType + ']').removeClass('first');
													
												} else {
													
													$('li[data-slideshow-prev=' + slideshowOptType + ']').removeClass('first');
													$('li[data-slideshow-next=' + slideshowOptType + ']').removeClass('last');
													
												}
			
											}
										);
										
								//
								// Arrows
								//
			
									slideshowNext
										.click(
											function(event){
												slideshowObj.trigger('next.owl.carousel');
												event.preventDefault();
											}
										);
			
									slideshowPrev
										.click(
											function(event){
												slideshowObj.trigger('prev.owl.carousel');
												event.preventDefault();
											}
										);
			
								//
								// Addons
								//		
			
						}
			
					);
				
			}
			
		);		
		
}
							