/*
* Environment
* ------------
* Detects browsers / devices for debugging and applies browser / device classes to the <html> tag
* ------------
* Version: 1.0
* Website: madebyshape.co.uk
* Author: Shape (@madebyshape)
* Dependencies: Platform (https://github.com/bestiejs/platform.js)
*/

var environment = {
	selectors: {
		html: $('html')
	},
	browser: {
		name: null,
		version: null
	},
	device: {
		name: null
	},
	getBrowser: function(){
		if (platform.name !== null) {
			this.browser['name'] = platform.name.replace(/\s+/g, '-').toLowerCase();
			this.browser['version'] = Math.round(parseInt(platform.version));
			this.selectors.html.addClass('browser-' + this.browser.name);
			this.selectors.html.addClass('browser-' + this.browser.name + '-' + this.browser.version);	
			debug.output(this.browser.name + '-' + this.browser.version);
		}
	},
	getDevice: function(){
		if (platform.product !== null) {
			this.device['name'] = platform.product.replace(/\s+/g, '-').toLowerCase();
			this.selectors.html.addClass('device-' + this.device.name);
			debug.output(this.device.name);
		}
	},
	init: function(){
		this.getBrowser();
		this.getDevice();
	}	
};