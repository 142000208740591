/*
* Viewport
* ------------
* Window viewport width, height, scroll position detects
* ------------
* Version: 1.0
* Website: madebyshape.co.uk
* Author: Shape (@madebyshape)
* Dependencies: None
*/

var viewport = {
	selectors: {
		window: $(window)
	},
	getWidth: function() {
		return this.selectors.window.width();
	},
	getHeight: function() {
		return this.selectors.window.height();			
	},
	getScroll: function() {
		return this.selectors.window.scrollTop();				
	},
	init: function() {
		debug.output(this.getWidth());
		debug.output(this.getHeight());
		debug.output(this.getScroll());
	}
};