/*
	*************************
	Shape Validation
	*************************

	---
	Developer(s)
	---

	Jason Mayo
	http://bymayo.co.uk
	http://twitter.com/madebymayo

*/

	// ------------------------
	// Build
	// ------------------------

		(function ($) {

			$.fn.shapeValidate = function(options) {

				//
				// Settings
				//

					var validateSettings = $.extend(
							true,
							{
								debug: false,
								disableSubmit: false,
								delay: 500,
								selectors: {
									invalid: 'validate-invalid',
									valid: 'validate-valid',
									required: 'validate-required',
									summary: 'validate-summary',
									alert: 'validate-alert',
									submit: 'validate-submit',
									submitted: 'validate-submitted',
									submitting: 'validate-submitting',
									customField: 'validate-custom-field'
								},
								alerts: {
									required: 'Required',
									summary: 'There are errors in the form, please fill all starred fields',
									submitting: 'Loading...',
									password: 'Must be a minimum of 8 characters',
									passwordConfirm: 'Passwords do not match',
									email: 'Required',
									number: 'Only numbers allowed'
								},
								gateways: {
									stripe: false
								}
							},
							options
						);

				//
				// Variables
				//

					var validateObj = this.selector;

					var validateFormId,
						validateReturn,
						validFlag = false,
						validateDataSubmitted = false;

				//
				// Regex
				//

					var validateRegexPassword = new RegExp(/^.{8,}$/),
						validateRegexNumber = new RegExp(/[0-9]|\./),
						validateRegexEmail = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);

				//
				// Debugging
				//

					function debug(name, message) {

						if(validateSettings.debug) {
							console.log('[Shape Validate]' + '[' + name + '] ' + message);
						}

					}

				//
				// Field Invalid
				//

					function validateFieldInvalid(object, message) {

						// Remove Valid Class

							object.removeClass(validateSettings.selectors.valid);

						// Add Invalid Class

							if (validateDataSubmitted === true) {
								object.addClass(validateSettings.selectors.invalid);
							}

						// Add Validation Alert

							if (object.next('.' + validateSettings.selectors.alert).length > 0) {

								object.next('.' + validateSettings.selectors.alert).text(message);

							}
							else {

								if (validateDataSubmitted === true) {
									object.after('<span class="' + validateSettings.selectors.alert + '">' + message + '</span>');
								}

							}

						// Add Validation Summary

							if ($('.' + validateSettings.selectors.summary).length < 1) {

								if ($(validateFormId + ' [type=submit]').length >= '1') {

									$(validateFormId + ' [type=submit]')
										.first()
										.before('<span class="' + validateSettings.selectors.summary + '">' + validateSettings.alerts.summary + '</span>');

								}
								else {

									$(validateFormId + ' .' + validateSettings.selectors.submit)
										.first()
										.before('<span class="' + validateSettings.selectors.summary + '">' + validateSettings.alerts.summary + '</span>');

								}

							}

					}

				//
				// Validate - Number
				//

					function validateNumber(value){

						return validateRegexNumber.test(value);

					}

				//
				// Validate - Email
				//

					function validateEmail(value) {

					    return validateRegexEmail.test(value);
					}

				//
				// Validate - Password
				//

					function validatePassword(value){

						return validateRegexPassword.test(value);

					}

				//
				// Validate - Password Confirm
				//

					function validatePasswordConfirm(value){

						var validatePasswordOriginal = $('[data-validate-helper=password]').val();

						if (validatePasswordOriginal === value) {
							return true;
						}
						else {
							return false;
						}

					}

				//
				// Validate - Options
				//

					function validateOptions(value, object) {

						var validateOptionsGroup = object.attr('name'),
							validateOptionsGroupObject = $('[name="' + validateOptionsGroup + '"]'),
							validateOptionsCount = $('[name="' + validateOptionsGroup + '"]').length;

						if (validateOptionsCount > 1) {

							// Check Minimum & Max

							return validateOptionsGroupObject.is(':checked');

						}
						else {

							return validateOptionsGroupObject.is(':checked');

						}

					}

				//
				// Add ID to all Validation forms
				//

					$(validateObj)
						.each(
							function(){

								var validateId = 'validate-' + Math.floor(Math.random() * (1000 - 1 + 1)) + 1,
									validateIdSelector = '[data-validate-id="' + validateId + '"]';

								$(this).attr('data-validate-id', validateId);

								// Find Return URL's

								if ($(validateIdSelector).data('validate-return') !== undefined){
									validateReturn = $('#' + validateId).data('validate-return');
								}
								else if ($(validateIdSelector + ' [name=return]').length > 0){
									validateReturn = $('#' + validateId + ' [name=return]').val();
								}
								else if ($(validateIdSelector + ' [name=return_url]').length > 0) {
									validateReturn = $('#' + validateId + ' [name=return_url]').val();
								}
								else {
									validateReturn = $(validateIdSelector + ' [name=RET]').val();
								}

							}
						);

				//
				// Initalise
				//

					function validate(dataSubmitted, dataFormId){

						validateDataSubmitted = dataSubmitted;

						$(dataFormId + ' .' + validateSettings.selectors.required).each(
							function(index){

								//
								// Object
								//

									var validateObject = $(this);

								//
								// Get current Value
								//

									var validateFieldValue = $(this).val(),
										validateFieldValueExist = $(this).attr('value'),
										validateFieldType = $(this).attr('type'),
										validateFieldHelper = $(this).attr('data-validate-helper');

									if (validateFieldType === undefined || validateFieldType === false) {
										validateFieldType = 'select';
									}

									debug('Field (' + index + ') Value', validateFieldValue);
									debug('Field (' + index + ') Value Attr', validateFieldValueExist);
									debug('Field (' + index + ') Type', validateFieldType);
									debug('Field (' + index + ') Helper', validateFieldHelper);


								//
								// Custom Required Message
								//

									var validateMessage = $(this).attr('data-validate-message');

								//
								// Default Message (If no required message is available)
								//

									if (validateMessage === null || validateMessage === undefined || validateMessage === false) {

										if (validateFieldType === 'password' && validateFieldHelper != 'password-confirm') {
											validateMessage = validateSettings.alerts.password;
										}
										else if (validateFieldType === 'password' && validateFieldHelper === 'password-confirm') {
											validateMessage = validateSettings.alerts.passwordConfirm;
										}
										else if (validateFieldType === 'email' || validateFieldHelper === 'email') {
											validateMessage = validateSettings.alerts.email;
										}
										else if (validateFieldType === 'number' || validateFieldHelper === 'number') {
											validateMessage = validateSettings.alerts.number;
										}
										else {
											validateMessage = validateSettings.alerts.required;
										}

									}

								//
								// Check if field value is blank
								//

									if (
										validateFieldValue === '' && validateFieldType != 'radio' ||
										validateFieldValue === '' && validateFieldType != 'checkbox' ||
										validateFieldValue === '' && validateFieldValueExist ===  undefined && validateFieldType === 'select' ||
										validateFieldValue === '' && validateFieldValueExist === false && validateFieldType === 'select'
										)
									{

										validateFieldInvalid(validateObject, validateMessage);

									}
									else {

										if (validateFieldType === 'email' && !validateEmail(validateFieldValue)) {

											validateFieldInvalid(validateObject, validateMessage);

										}
										else if (validateFieldType === 'number' && !validateNumber(validateFieldValue)) {

											validateFieldInvalid(validateObject, validateMessage);

										}
										else if (validateFieldType === 'password' && validateFieldHelper != 'password-confirm' && !validatePassword (validateFieldValue)) {

											validateFieldInvalid(validateObject, validateMessage);

										}
										else if (validateFieldType === 'password' && validateFieldHelper === 'password-confirm' && !validatePasswordConfirm (validateFieldValue)) {

											validateFieldInvalid(validateObject, validateMessage);

										}
										else if ( validateFieldType === 'checkbox' && !validateOptions(validateFieldValue, validateObject) || validateFieldType === 'radio' && !validateOptions(validateFieldValue, validateObject))
										{

											validateFieldInvalid(validateObject, validateMessage);

										}
										else {

											// Make Valid & Remove Alert

												validateObject
													.removeClass(validateSettings.selectors.invalid)
													.addClass(validateSettings.selectors.valid)
													.next('.' + validateSettings.selectors.alert)
													.remove();

										}

									}

							}
						);

						var validateRequiredCount = $(validateFormId + ' .' + validateSettings.selectors.required).length;
						var validateValidCount = $(validateFormId + ' .' + validateSettings.selectors.valid).length;

						if (validateRequiredCount === validateValidCount) {
							$(dataFormId).removeClass(validateSettings.selectors.submitted);
							$('.' + validateSettings.selectors.summary).remove();
						}

						if (validateRequiredCount === validateValidCount && validateDataSubmitted === true) {

							// Change Flag

								validFlag = true;

							// Create URL

								var validateUrlProtocol = window.location.protocol;
								var validateUrlHostname = window.location.hostname;
								var validateUrl = validateUrlProtocol + '//' + validateUrlHostname + '/';

							// Get Form URL's

								var validateUrlAction = $(dataFormId).attr('action');

							// Add "Loading"

								// Input Buttons

									$(dataFormId + ' [type=submit]')
										.val(validateSettings.alerts.submitting)
										.addClass(validateSettings.selectors.submitting);

								// Buttons & Anchors

									$(dataFormId + ' .' + validateSettings.selectors.submit)
										.text(validateSettings.alerts.submitting)
										.addClass(validateSettings.selectors.submitting);

						}

					}

				//
				// On Change
				//

					$('.' + validateSettings.selectors.required)
						.on('change',
							function(){
								validateFormId = '[data-validate-id="' + $(this).closest(validateObj).attr('data-validate-id') + '"]';
								validate(false, validateFormId);
							}
						);

				//
				// On Key Up
				//

					$('.' + validateSettings.selectors.required)
						.on('keyup',
							function(){
								validateFormId = '[data-validate-id="' + $(this).closest(validateObj).attr('data-validate-id') + '"]';
								validate(false, validateFormId);
							}
						);


				//
				// On Click
				//

					$('.' + validateSettings.selectors.customField)
						.on('click',
							function(){
								
								validateFormId = '[data-validate-id="' + $(this).closest(validateObj).attr('data-validate-id') + '"]';
								validate(false, validateFormId);
								
							}
						);

				
				//
				// On Submit
				//

					$(validateObj + ' [type=submit], ' + validateObj + ' .' + validateSettings.selectors.submit)
						.on('click',
							function(event){

								validateFormId = '[data-validate-id="' + $(this).closest(validateObj).attr('data-validate-id') + '"]';

								$(validateFormId).addClass(validateSettings.selectors.submitted);

								validate(true, validateFormId);

								if (validFlag === true && validateSettings.disableSubmit === false) {
									// Enable Form Submit
									if (!validateSettings.gateways.stripe) {
										$(validateFormId).submit();	
									}
								}
								else {
									// Disable Form Submit
									return false;
									event.preventDefault();
								}

							}
						);

			};

		}( jQuery ));