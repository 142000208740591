/*
* Debug
* ------------
* Enables you to turn off console.log's with a simple variable change
* ------------
* Version: 1.0
* Website: madebyshape.co.uk
* Author: Shape (@madebyshape)
* Dependencies: None
*/

var debug = {
	settings: {
		enabled: true
	},
	output: function(string) {
		if (this.settings.enabled) {
			console.log(string);
		}
	}
};