/*
* App
* ------------
* Version: 1.0
* Website: madebyshape.co.uk
* Author: Shape (@madebyshape)
* Dependencies: jQuery (https://jquery.com), Modernizr (https://github.com/Modernizr/Modernizr)
*/

// ----------------------------------------------------------------------------------------------------------------

//
// Variables
//

	var windowResizeWidth = viewport.getWidth();

	var breakpoint = {
		small: 0,
		medium: 640,
		large: 1024,
		xlarge: 1200,
		xxlarge: 1440
	}

	var state = {
		hidden: 'is-hidden',
		visible: 'is-visible',
		selected: 'is-selected',
		active: 'is-active',
		removed: 'is-removed',
		processing: 'is-processing',
		loading: 'is-loading'
	}

	var mediaSmallX = "only screen and (max-width: 700px)",
		mediaNavX = "only screen and (max-width: 850px)",
		mediaSmallMediumX = "only screen and (max-width: 960px)",
		mediaMediumX = "only screen and (max-device-width: 1024px)";

// ----------------------------------------------------------------------------------------------------------------

//
// Modules
//

	// --------------------------------------------------------

	// Scroll Effects (Scroll Reveal)

	var scrollEffects = {
		selectors: {
			window: window.sr
		},
		options: {
			origin: 'bottom',
			distance: '10vh',
			duration: 500,
			delay: 0,
			opacity: 0,
			scale: 1,
			easing: 'cubic-bezier(0.6, 0.2, 0.1, 1)',
			mobile: true,
			reset: false,
			viewFactor: 0.2
		},
		attach: function() {
			this.selectors.window = ScrollReveal();
			this.selectors.window.reveal('.js-scroll-effect', this.options);
		},
		init: function(){
			this.attach();
		}
	}

	//


	var lightbox = {
		init: function() {

			if ( $('#lightboxOpenOnLoad').length != 0 ) {

				$.magnificPopup.open({
	            items: {
	                src: '#lightboxOpenOnLoad'
	            },
	            type: 'inline'
	        }, 0);

		  }

			$('.js-popup-inline').magnificPopup({
					type:'inline',
					midClick: true
				});

			$('.js-popup-youtube, .js-popup-vimeo').magnificPopup({
				disableOn: 700,
				type: 'iframe',
				mainClass: 'mfp-fade',
				removalDelay: 160,
				preloader: false,

				fixedContentPos: false
			});

			$('.js-gallery').magnificPopup({
				delegate: 'a',
				type: 'image',
				tLoading: 'Loading image #%curr%...',
				mainClass: 'mfp-img-mobile',
				gallery: {
					enabled: true,
					navigateByImgClick: true,
					preload: [0,1], // Will preload 0 - before current, and 1 after the current image
					arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button
					tPrev: 'Previous (Left arrow key)', // title for left button
					tNext: 'Next (Right arrow key)', // title for right button
					tCounter: '<span class="mfp-counter">%curr% of %total%</span>', // markup of counter
				},
				image: {
					tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
				}
			});

		}
	}

	// Scroll To

	var scrollTo = {
		init: function() {

			$('.js-scroll-to')
				.click(

					function(event) {

						var id = $(this).data('scroll-target'),
							scrollOffset = 20;

						console.log(id);

						$('html, body')
							.stop()
							.animate(
								{
									'scrollTop': $('.js-scroll-target[data-scroll-id=' + id + ']').offset().top -scrollOffset
						 		}, 600
						 	);

						event.preventDefault();

					}

				);

		}
	}

	// Remove Touch Delay (FastClick)

	var removeTouchDelay = {
		init: function(){
			FastClick.attach(document.body);
		}
	}

	// Validation

	var validation = {
		selectors: {
			form: $('.js-form-validate')
		},
		init: function() {

				this.selectors.form
	    			.shapeValidate(
		    			{
			    			selectors: {
				    			required: 'is-required'
				    		}
		    			}
	    			);

		}
	}

	// Share

	var share = {
		init: function() {

			$('.js-social-share')
    			.shapeShare();

		}

	}

	// SVG Convert

	var svgConvert = {
		init: function() {

			$('.js-svg-convert')
    			.shapeSvgConvert();

		}
	}

	// Sibling Hover

	var siblingHover = {
		selectors: {
			item: $('[data-sibling-hover]')
		},
		init: function(){

			var itemIndex = 0;

			this.selectors.item.hover(
				function(){
					var siblingHoverId = $(this).data('sibling-hover');
					$('[data-sibling-hover=' + siblingHoverId + ']')
						.each(
							function(){
								$(this).addClass('is-inactive');
							}
						);
					$(this)
						.removeClass('is-inactive');
		        },
		        function(){
		        	$('[data-sibling-hover]').removeClass('is-inactive');
			    }

			);

		}
	}

	// Sticky Kit

	var sticky = {
		init: function() {

			if ( !Modernizr.mq(mediaMediumX) ) {


				$('html')
					.imagesLoaded(

						function() {

							$(".js-sticky")
								.stick_in_parent(
									{
										offset_top: 20,
										bottoming: true,
										recalc_every: 1
									}
								);

						}

					);

			}

		}
	}

	// Social Panel

	var socialPanel = {
		init: function() {

			$('[data-social-panel-link]')
				.hover(

					function() {

						var id = $(this).data('social-panel-link');

						$('.js-social-panel')
							.addClass('colour-background-' + id);

					},

					function() {

						$('.js-social-panel')
							.removeClass('colour-background-twitter')
							.removeClass('colour-background-facebook')
							.removeClass('colour-background-youTube')
							.removeClass('colour-background-linkedIn');

					}

				);

		}
	}


	// Input Checkbox

	var inputCheckbox = {
		init: function() {

			$('.js-input-checkbox')
				.click(

					function(event) {

						if ( $(this).hasClass(state.active) ) {

							$(this)
								.removeClass(state.active)
								.children('input')
								.removeAttr('checked');

						}

						else {

							$(this)
								.addClass(state.active)
								.children('input')
								.prop('checked', true);

						}

						if ( !$(this).hasClass('js-type-multiple-select') ) {

							$(this)
								.siblings('.js-input-checkbox')
								.removeClass(state.active)
								.children('input')
								.removeAttr('checked');

						}

						$('.js-sticky-filter, .js-sticky-filter-title')
							.removeClass(state.active);

						$('.js-loader')
							.addClass(state.active);

						$('.js-form-filter')
							.addClass(state.loading)
							.submit();

						event.preventDefault();

					}

				);

		}
	}

	// Nav Toggle

	var nav = {
		init: function() {

			$('.js-site-burger')
				.click(

					function(event) {

						$('.js-site-burger, .js-sidebar, .js-site-wrapper, body')
							.toggleClass(state.active);

						event.preventDefault();

					}

				);



			$('.js-sidebar-close')
				.click(

					function(event) {

						$('.js-site-burger, .js-sidebar, .js-site-wrapper, body')
							.removeClass(state.active);

						event.preventDefault();

					}

				);

		}
	}


	// Sticky Filter

	var stickyFilter = {
		init: function() {

			$('.js-sticky-filter-title')
				.click(

					function(event) {

						$('.js-sticky-filter, .js-sticky-filter-title')
							.toggleClass(state.active);

						event.preventDefault();

					}

				);

		}
	}

	// Tab Content

	var tabContent = {
		init: function() {

			$('.js-tab-content-action')
				.click(

					function(event) {

						var id = $(this).data('id');

						$('.js-tab-content-action, .js-tab-content-item')
							.removeClass(state.active);

						$('.js-tab-content-item[data-id=' + id + '], .js-tab-content-action[data-id=' + id + ']')
							.addClass(state.active);

						event.preventDefault();

					}

				);

		}
	}

	// Map

	var map = {
		init: function() {

			$('.js-map-action, .js-map .path-region')
				.click(

					function(event) {

						var id = $(this).data('id');

						$('.js-map-action, .js-map-item, .js-map .path-region')
							.removeClass(state.active);

						$('.js-map-item[data-id=' + id + '], .js-map-action[data-id=' + id + '], .js-map .path-region[data-id=' + id + ']')
							.addClass(state.active);

						event.preventDefault();

					}

				);

		}
	}

	// Table

	var table = {
		init: function() {

			$('.js-table').stacktable();

		}
	}

// ----------------------------------------------------------------------------------------------------------------

//
// Initalise
//

	// Ready

	$(document)
		.ready(
			function(){
				environment.init();
				removeTouchDelay.init();
				scrollEffects.init();
				inputCheckbox.init();
				svgConvert.init();
				share.init();
				scrollTo.init();
				validation.init();
				lightbox.init();
				siblingHover.init();
				socialPanel.init();
				sticky.init();
				nav.init();
				stickyFilter.init();
				tabContent.init();
				map.init();
				table.init();
			}
		);

	// Resize

	$(window)
		.resize(
			function(){

				if ($(window).width() != windowResizeWidth) {
					windowResizeWidth = viewport.getWidth(); // Required for mobile Safari & Chrome
				}
			}
		);

	// Scroll

	$(window)
		.scroll(
			function(){


			}
		);
